<template>
  <div class="payment_box" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="payment-top">
      <div class="top_bax">
        <p class="title">Payment Schedule</p>
      </div>
      <!-- 选择分期数 -->
      <div class="pay_select">
        <div class="pay_selectleft">
          <span class="text">Select # of Payments</span>
          <el-select v-model="selectPeriod" @change="countChange" :disabled="data.status && data.status != 'Draft'|| data.is_co_broke_lease&&data.represent==='tenant'">
            <el-option v-for="item in count" :key="item" :label="item" :value="item" ></el-option>
          </el-select>
        </div>
      </div>
      <Patment-Form :data="data" :excess="excess" :payments="payments" :selectPeriod="selectPeriod" ref="paymentShow" @rentChange="rentChange"></Patment-Form>
      <!-- PAYMENT STATUS -->
      <!-- <div class="pay_content">
        <div class="pay_title row">
          <span>PAYMENT STATUS</span>
          <el-button
            class="block-button"
            @click="$refs.check.show = true"
            :disabled="$store.state.userInfo.is_admin === false"
            >Receive Payment</el-button
          >
        </div>
        <Laese-Payment-Status :data="data"></Laese-Payment-Status>
      </div> -->
      <!-- 填写支票信息的页面 -->
      <!-- <Check-Dialog ref="check" :data="data" :count="count"></Check-Dialog> -->
      <!-- <AuditTrail></AuditTrail> -->
    </div>
    <div class="payment-bottom row">
      <el-button class="plight-blue-button" @click="back" :disabled="data.status && data.status != 'Draft'" ><p class="row">
          <i class="el-icon-back"></i><span>Previous</span>
        </p></el-button>
      <el-button class="primary-button" @click="updatePayments" :disabled="data.status && data.status != 'Draft'" ><p class="row"><span>Confirm</span><i class="el-icon-right"></i></p ></el-button>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import {apiGetLease, apiUpdateLease, apiDeleteLease,apiSendDisbursementForm} from "../../API/api";
import LeaseDisbursement from "./PaymentSchedule/LeaseDisbursement";
import LeaseTopInfor from "./Component/LeaseTopInfor";
import LaesePaymentStatus from "./PaymentSchedule/LeasePaymentStatus";
import CancelDialog from "./Component/LeaseCancelDialog";
import CheckDialog from "./PaymentSchedule/LeaseCheck";
import PatmentForm from "./PaymentSchedule/LeasePaymentForm"
import AuditTrail from "./PaymentSchedule/LeaseAuditTrail"
function keepTwoDecimal(num) { // 采用四舍五入，保存两位小数
  return Math.round(parseFloat(num) * 100) / 100;
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
export default {
  components: { LeaseTopInfor, CancelDialog, CheckDialog, LeaseDisbursement, LaesePaymentStatus,  PatmentForm, AuditTrail},
  data() {
    return {
      excess: 0,
      flag: this.$route.params.id ? false : true, // 首次使用接口返回的数据展示，修改分期数后重新计算payments
      fullscreenLoading: false,
      selectPeriod: 0, // 选择的分期数，默认两期
      payments: [],
      showDisbursementDialog: false, // 显示财务报表对话框
      disbursementForm: {},
      data: {},
    };
  },
  computed: {
    // 可以分期数
    count() {
      if (
        this.data.arrival_date &&
        this.data.departure_date &&
        dayjs(this.data.arrival_date) < dayjs(this.data.departure_date)
      ) {
        let now = dayjs(dayjs().format("MM/DD/YYYY"));
        let arrival_date = dayjs(this.data.arrival_date);
        // 最后一期逾期日期 - 第一期逾期日期
        let day = arrival_date.add(-45, "day").diff(now.add(7, "day"), "day");
        if (day <= 0) {
          // 只有一期
          return 1;
        } else if (day === 1) {
          // 可以分两期
          return 2;
        } else if (day === 2) {
          // 可以分三期
          return 3;
        } else if (day > 2) {
          // 可以分四期
          return 4;
        }
      }
    },
  },
  methods: {
    countChange(){//选择期数
      if (this.flag) {
        this.$refs.paymentShow.showExcess = false;
        this.payments = this.getPayments();
      }
    },
    back() {
      this.$router.push("/lease/" + this.$route.params.id);
    },
    rentChange(payment, index) {// 租金改变
      payment.total_amount = calcTotalPrice(payment.bill);
      this.$refs.paymentShow.showExcess = false;
      this.payments = this.getPayments(payment, index);
    },
    getPayments(item, index) {// 获取分期内容
      let now = dayjs(dayjs().format("YYYY-MM-DD"));
      let arrival_date = dayjs(this.data.arrival_date);
      if (this.selectPeriod === 1) {
        // 分一期
        let due_date = "";
        if (arrival_date.add(-7) <= now) {
          // 到达日期在一周之内，当天就要支付第一期费用
          due_date = now; // 当天逾期
        } else {
          // 到达日期再一周之后，支付第一期费用需要在7天后
          due_date = now.add(7, "day"); // 逾期时间为：租约创建的日期加7天
        }
        return [
          {
            period: 1,
            due_date: due_date.format("YYYY-MM-DD"),
            total_amount: this.data.total_amount,
            bill: {
              rent: this.data.rent,
              processing_fee: this.data.processing_fee,
              tax: this.data.tax,
              security_deposit: this.data.security_deposit
                ? this.data.security_deposit_amount
                : 0.0,
              other_fee: this.data.other_fee,
            },
          },
        ];
      } else if (this.selectPeriod === 2) {
        // 分两期
        let cost1 = {
          rent: keepTwoDecimal(this.data.rent / 2),
          processing_fee: this.data.processing_fee,
          tax: this.data.tax,
        };
        let cost2 = {
          rent: keepTwoDecimal(
            this.data.rent - keepTwoDecimal(this.data.rent / 2)
          ),
          security_deposit: this.data.security_deposit
            ? this.data.security_deposit_amount
            : 0.0,
          other_fee: this.data.other_fee,
        };
        if (item) {
          if (index === 0) {
            cost1.rent = item.bill.rent;
            cost2.rent = keepTwoDecimal(this.data.rent - item.bill.rent);
          } else if (index === 1) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = item.bill.rent;
            let totalexcess = keepTwoDecimal(
              parseFloat(item.bill.rent) +
                parseFloat(this.payments[0].bill.rent)
            );
            if (this.data.rent != totalexcess) {
              this.excess = keepTwoDecimal(this.data.rent - totalexcess);
              this.$refs.paymentShow.showExcess = true;
            } else {
              this.$refs.paymentShow.showExcess = false;
            }
          }
        }

        return [
          {
            // 手续费、国家税、地方税第一期支付
            period: 1,
            due_date: now.add(7, "day").format("YYYY-MM-DD"), // 第一期的逾期时间是创建租约后的7天
            total_amount: calcTotalPrice(cost1),
            bill: cost1,
          },
          {
            // 保证金、其它费用在最后一期支付
            period: 2,
            due_date: arrival_date.add(-45, "day").format("YYYY-MM-DD"), // 最后一期的逾期时间是到达日期的前45天
            total_amount: calcTotalPrice(cost2),
            bill: cost2,
          },
        ];
      } else if (this.selectPeriod === 3) {
        // 分三期

        let cost1 = {
          // rent: data||(keepTwoDecimal(this.data.rent / 3)!=data.bill.rent)?data.bill.rent:keepTwoDecimal(this.data.rent / 3),
          rent: keepTwoDecimal(this.data.rent / 3),
          processing_fee: this.data.processing_fee,
          tax: this.data.tax,
        };
        let cost2 = {
          rent: keepTwoDecimal(this.data.rent / 3),
        };
        let cost3 = {
          rent: keepTwoDecimal(
            this.data.rent - keepTwoDecimal(this.data.rent / 3) * 2
          ),
          security_deposit: this.data.security_deposit
            ? this.data.security_deposit_amount
            : 0.0,
          other_fee: this.data.other_fee,
        };
        if (item) {
          if (index === 0) {
            cost1.rent = item.bill.rent;
            cost2.rent = keepTwoDecimal(
              keepTwoDecimal(this.data.rent - item.bill.rent) / 2
            );
            cost3.rent = keepTwoDecimal(
              this.data.rent -
                item.bill.rent -
                keepTwoDecimal(
                  keepTwoDecimal(this.data.rent - item.bill.rent) / 2
                )
            );
          } else if (index === 1) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = item.bill.rent;
            cost3.rent = keepTwoDecimal(
              this.data.rent - item.bill.rent - this.payments[0].bill.rent
            );
          } else if (index === 2) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = this.payments[1].bill.rent;
            cost3.rent = item.bill.rent;
            let totalexcess = keepTwoDecimal(
              parseFloat(item.bill.rent) +
                parseFloat(this.payments[1].bill.rent) +
                parseFloat(this.payments[0].bill.rent)
            );
            if (this.data.rent != totalexcess) {
              this.excess = keepTwoDecimal(this.data.rent - totalexcess);
              this.$refs.paymentShow.showExcess = true;
            } else {
              this.$refs.paymentShow.showExcess = false;
            }
          }
        }

        let dueDate1 = now.add(7, "day");
        let dueDate3 = arrival_date.add(-45, "day");
        let dueDate2 = dueDate1.add(
          Math.ceil(dueDate3.diff(dueDate1, "day") / 2),
          "day"
        );
        return [
          {
            // 手续费、国家税、地方税第一期支付
            period: 1,
            due_date: dueDate1.format("YYYY-MM-DD"), // 第一期的逾期时间是创建租约后的7天
            total_amount: calcTotalPrice(cost1),
            bill: cost1,
          },
          {
            period: 2,
            due_date: dueDate2.format("YYYY-MM-DD"), // 第二期逾期时间距离第一期和最后一期相差的时间要相等
            total_amount: calcTotalPrice(cost2),
            bill: cost2,
          },
          {
            // 保证金、其它费用在最后一期支付
            period: 3,
            due_date: dueDate3.format("YYYY-MM-DD"), // 最后一期的逾期时间是到达日期的前45天
            total_amount: calcTotalPrice(cost3),
            bill: cost3,
          },
        ];
      } else if (this.selectPeriod === 4) {
        // 分四期
        let cost1 = {
          rent: keepTwoDecimal(this.data.rent / 4),
          processing_fee: this.data.processing_fee,
          tax: this.data.tax,
        };
        let cost2 = {
          rent: keepTwoDecimal(this.data.rent / 4),
        };
        let cost3 = {
          rent: keepTwoDecimal(this.data.rent / 4),
        };
        let cost4 = {
          rent: keepTwoDecimal(
            this.data.rent - keepTwoDecimal(this.data.rent / 4) * 3
          ),
          security_deposit: this.data.security_deposit
            ? this.data.security_deposit_amount
            : 0.0,
          other_fee: this.data.other_fee,
        };
        if (item) {
          if (index === 0) {
            cost1.rent = item.bill.rent;
            cost2.rent = keepTwoDecimal((this.data.rent - item.bill.rent) / 3);
            cost3.rent = keepTwoDecimal((this.data.rent - item.bill.rent) / 3);
            cost4.rent = keepTwoDecimal(
              this.data.rent -
                item.bill.rent -
                keepTwoDecimal((this.data.rent - item.bill.rent) / 3) * 2
            );
          } else if (index === 1) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = item.bill.rent;
            cost3.rent = keepTwoDecimal(
              (this.data.rent - item.bill.rent - this.payments[0].bill.rent) / 2
            );
            cost4.rent = keepTwoDecimal(
              this.data.rent -
                this.payments[0].bill.rent -
                item.bill.rent -
                keepTwoDecimal(
                  (this.data.rent -
                    item.bill.rent -
                    this.payments[0].bill.rent) /
                    2
                )
            );
          } else if (index === 2) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = this.payments[1].bill.rent;
            cost3.rent = item.bill.rent;
            cost4.rent = keepTwoDecimal(
              this.data.rent -
                item.bill.rent -
                this.payments[0].bill.rent -
                this.payments[1].bill.rent
            );
          } else if (index === 3) {
            cost1.rent = this.payments[0].bill.rent;
            cost2.rent = this.payments[1].bill.rent;
            cost3.rent = this.payments[2].bill.rent;
            cost4.rent = item.bill.rent;
            let totalexcess = keepTwoDecimal(
              parseFloat(item.bill.rent) +
                parseFloat(this.payments[2].bill.rent) +
                parseFloat(this.payments[1].bill.rent) +
                parseFloat(this.payments[0].bill.rent)
            );
            if (this.data.rent != totalexcess) {
              this.excess = keepTwoDecimal(this.data.rent - totalexcess);
              this.$refs.paymentShow.showExcess = true;
            } else {
              this.$refs.paymentShow.showExcess = false;
            }
          }
        }

        let dueDate1 = now.add(7, "day");
        let dueDate4 = arrival_date.add(-45, "day");
        let dueDate2 = dueDate1.add(
          Math.ceil(dueDate4.diff(dueDate1, "day") / 3),
          "day"
        );
        let dueDate3 = dueDate1.add(
          Math.ceil(dueDate4.diff(dueDate1, "day") / 3) * 2,
          "day"
        );
        return [
          {
            // 手续费、国家税、地方税第一期支付
            period: 1,
            due_date: dueDate1.format("YYYY-MM-DD"), // 第一期的逾期时间是创建租约后的7天
            total_amount: calcTotalPrice(cost1),
            bill: cost1,
          },
          {
            period: 2,
            due_date: dueDate2.format("YYYY-MM-DD"), // 第二期逾期时间
            total_amount: calcTotalPrice(cost2),
            bill: cost2,
          },
          {
            period: 3,
            due_date: dueDate3.format("YYYY-MM-DD"), // 第三期逾期时间
            total_amount: calcTotalPrice(cost3),
            bill: cost3,
          },
          {
            // 保证金、其它费用在最后一期支付
            period: 4,
            due_date: dueDate4.format("YYYY-MM-DD"), // 最后一期的逾期时间是到达日期的前45天
            total_amount: calcTotalPrice(cost4),
            bill: cost4,
          },
        ];
      }
    },
    updatePayments() {// 修改租约分期付款
      let totalRent = keepTwoDecimal(
        this.payments.reduce((previous, current) => {
          return previous + (current.bill["rent"] - 0);
        }, 0)
      );
      if (totalRent != this.data.rent) {
        this.$message.error("The total rent is not equal to " + this.data.rent);
        return;
      }
      this.fullscreenLoading = true;
      apiUpdateLease(this.$route.params.id, {
        payments: this.payments,
        other_fee_comment: this.data.other_fee_comment,
        processing_fee: this.payments[0].bill["processing_fee"],
      })
        .then((res) => {
          this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
          this.data = res;
          this.selectPeriod = this.data.payments.length;
          this.payments = this.data.payments;
          this.$router.push("/lease/" + this.$route.params.id + "/agreement");
          this.$message.success("Updated lease successful");
        })
        .catch((err) => {
          this.$message.error("Updated lease fail");
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    getLease() {// 获取租约详情
      apiGetLease(this.$route.params.id).then((res) => {
        this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
        res.payments.forEach((item, index) => {
          if (item.qb_info) {
            if (item.qb_info.agent_bill_payment_ref&&item.qb_info.agent_bill_payment_ref.indexOf("bill.com Check Number") != -1) {
               item.qb_info.agent_bill_payment_ref = item.qb_info.agent_bill_payment_ref.split(
                ":"
              )[1];
            }

            if (item.qb_info.homeowner_bill_payment_ref&&item.qb_info.homeowner_bill_payment_ref.indexOf("bill.com Check Number") != -1) {
               item.qb_info.homeowner_bill_payment_ref = item.qb_info.homeowner_bill_payment_ref.split(
                ":"
              )[1];
            }
          }
        });
        this.data = res;
        this.selectPeriod = this.data.payments.length;
        this.payments = this.data.payments;
        this.$store.commit("setLeaseDetail", true);
        if (res.sign_info || this.data.sign_file_info) {
          this.$store.commit("setLeaseAgeent", true);
        }
        if (res.security_deposit.status === "Paid") {
          this.$store.commit("setLeaseSecurityDeposit", true);
        }
        setTimeout(() => {
          this.flag = true;
        }, 100);
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      if (this.$store.state.lease.lease_id === this.$route.params.id - 0) {
        let obj = JSON.parse(JSON.stringify(this.$store.state.lease));
        obj.payments.forEach((item, index) => {
          if (item.qb_info) {
            if (item.qb_info.agent_bill_payment_ref&&item.qb_info.agent_bill_payment_ref.indexOf(":") != 1) {
              item.qb_info.agent_bill_payment_ref = item.qb_info.agent_bill_payment_ref.split(
                ":"
              )[1];
            }
            if (item.qb_info.homeowner_bill_payment_ref&&item.qb_info.homeowner_bill_payment_ref.indexOf(":") != 1) {
              item.qb_info.homeowner_bill_payment_ref = item.qb_info.homeowner_bill_payment_ref.split(
                ":"
              )[1];
            }
          }
        });
        this.data = obj;
        this.selectPeriod = this.data.payments.length;
        this.payments = this.data.payments;
        setTimeout(() => {
          this.flag = true;
        }, 100);
      } else {
        this.getLease();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./LeasePayments.scss";
</style>
