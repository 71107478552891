<template>
  <div class="audit-box">
    <p class="title">Audit Trail</p>
    <div class="table">
      <el-table :data="data">
        <el-table-column label="DATE" width="100">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="WHO" prop="date"></el-table-column>
        <el-table-column label="FIELD">
          <template slot-scope="scope">
            <span>{{ scope.row.amount | currencyPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="PMT #" prop="check_number"></el-table-column>
        <el-table-column label="OLD VALUE">
          <template slot-scope="scope">
            <span class="btn" @click="cancelDel(scope.row.check_uuid)"
              >Delete</span
            >
          </template>
        </el-table-column>
        <el-table-column label="NEW VALUE">
          <template slot-scope="scope">
            <span class="btn" @click="cancelDel(scope.row.check_uuid)"
              >Delete</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  prop: ["data"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.audit-box {
    .title{
      margin-bottom: 0px;
      text-align: left;
      color: #36425D;
      font-size: 18px;
      font-family: "Roboto-Bold";
    }
}
</style>