<template>
  <div class="payment-status-box">
    <div class="deposit_table" style="overflow: auto">
      <table
        width="100%"
        style="white-space: nowrap; border-collapse: collapse"
      >
        <tr class="table_top">
          <th>PMT #</th>
          <th>DUE DATE</th>
          <th>TOTAL DUE</th>
          <th>PMT REC'D</th>
          <th>BALANCE DUE</th>
          <th>STATUS</th>
          <th>PMT DATE</th>
          <!-- <th>PAYMENT REF</th> -->
          <th></th>
        </tr>
        <tr v-for="(item, index) in data.payments" :key="index">
          <td>{{ item.period }}</td>
          <td>{{ item.due_date | dateFormat }}</td>
          <td>${{ item.total_amount | currencyPrice }}</td>
          <td>${{ item.amount_received | currencyPrice }}</td>
          <td>${{ item.balance | currencyPrice }}</td>
          <td>
            <span
              class="lb_color"
              v-if="item.status == 'Paid'"
              style="background-color: #73edb0"
            ></span>
            <span
              class="lb_color"
              v-else-if="item.status == 'Past Due'"
              style="background-color: #db272d"
            ></span>
            <span
              class="lb_color"
              v-else
              style="background-color: #fbca25"
            ></span>
            <span v-if="item.status == 'Paid' || item.status == 'Past Due'">{{
              item.status
            }}</span>
            <span v-else>{{ item.status }}</span>
          </td>
          <td>
            <!--  -->
            <!-- <el-date-picker
              v-if="$store.state.userInfo.is_admin === true"
              v-model="item.payment_date"
              type="date"
              placeholder=""
              format="MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              style="width: 140px"
              @change="changeQBPaymentDate(item)"
            >
            </el-date-picker> -->
            <span>{{item.payment_date ? item.payment_date : null | dateFormat}}</span>
          </td>
          <!-- <td style="width:200px;">
            <el-input ></el-input>
          </td> -->
          <td>
            <!--  -->
            <el-popover placement="bottom" width="100" trigger="hover">
              <div style="text-align: left; margin: 0">
                <p>
                  <el-button
                    @click="sendReminderToTenant(item.lease)"
                    type="text"
                    :loading="loadingReminder"
                    style="
                      color: #38425b;
                      font-size: 16px;
                      font-family: 'Roboto-Regular', sans-serif;
                    "
                    >Send Reminder</el-button
                  >
                </p>
                <p>
                  <el-button
                    @click="showDisbursementForm(item, index)"
                    type="text"
                    v-if="$store.state.userInfo.is_admin === true||
                      data.user === $store.state.userInfo.user_id
                    "
                    style="
                      color: #38425b;
                      font-size: 16px;
                      font-family: 'Roboto-Regular', sans-serif;
                    "
                    >Disbursement</el-button
                  >
                </p>
              </div>
              <el-button
                type="text"
                slot="reference"
                icon="el-icon-more"
              ></el-button>
            </el-popover>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="table_totle">$ {{ totalAmount | currencyPrice }}</td>
          <td class="table_totle">
            $ {{ totalReceivedAmount | currencyPrice }}
          </td>
          <td class="table_totle">
            $ {{ totalBalanceAmount | currencyPrice }}
          </td>
          <td></td>
          <td></td>
          <!-- <td></td> -->
          <td></td>
        </tr>
      </table>
    </div>
    <Lease-Disbursement
      :data="disbursementForm"
      :qb_info="disbursementForm.qb_info"
      :co_broke_agency="data.co_broke_agency"
      ref="showDisbursement"
    ></Lease-Disbursement>
  </div>
</template>

<script>
import { apiSendNotificationAgent, apiUpdateQBInfor } from "../../../API/api";
function keepTwoDecimal(num) {
  // 采用四舍五入，保存两位小数
  return Math.round(parseFloat(num) * 100) / 100;
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
import LeaseDisbursement from "./LeaseDisbursement";
export default {
  props: ["data"],
  data() {
    return {
      disbursementForm: {},
      loadingReminder: false,
    };
  },
  components: { LeaseDisbursement },
  computed: {
    totalAmount() {
      if (this.data.payments) {
        return keepTwoDecimal(
          this.data.payments.reduce((previous, current) => {
            return previous + (current.total_amount - 0);
          }, 0)
        );
      } else {
        return 0;
      }
    },
    totalReceivedAmount() {
      if (this.data.payments) {
        return keepTwoDecimal(
          this.data.payments.reduce((previous, current) => {
            return previous + (current.amount_received - 0);
          }, 0)
        );
      } else {
        return 0;
      }
    },
    totalBalanceAmount() {
      if (this.data.payments) {
        return keepTwoDecimal(
          this.data.payments.reduce((previous, current) => {
            return previous + (current.balance - 0);
          }, 0)
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    changeQBPaymentDate(item) {
      let obj = {
        payment: item.payment_uuid,
        payment_date: item.qb_info.payment_date
          ? item.qb_info.payment_date
          : null,
      };
      apiUpdateQBInfor(obj)
        .then((res) => {
          this.$message.success("Success");
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    // 发送邮件通知租客
    sendReminderToTenant(id) {
      this.loadingReminder = true;
      apiSendNotificationAgent({ lease: id })
        .then((res) => {
          this.$message.success("Successfully sent reminder to tenant");
        })
        .finally(() => {
          this.loadingReminder = false;
        });
    },
    // 显示财务支出表
    showDisbursementForm(item) {
      this.$refs.showDisbursement.show = true;
      let info = {
        received_amount: item.amount_received,
      };
      item = Object.assign(item, info);
      
      this.disbursementForm = item;
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-status-box {
  .deposit_table {
    padding: 10px 0 40px 0;
    .table_totle {
      color: #333;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 20px;
    }
    .lb_color {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 5px;
    }
    /deep/ .el-icon-more {
      transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      color: #b5b9c4;
    }
    table tr {
      font-size: 14px;
      color: #666;
      text-align: left;
      th {
        padding: 10px 0px;
        font-size: 12px;
        color: #737a8c;
        font-family: "Roboto-Medium", sans-serif;
        border-bottom: 1px solid #e1e9ef;
      }
      td {
        padding-top: 10px;
        border-bottom: 1px solid #e1e9ef;
        font-family: "Roboto-Regular", sans-serif;
      }
      &:last-of-type td {
        border: none;
      }
    }
  }
}
</style>