<template>
  <div class="check">
    <el-dialog
      title="Receive Payment"
      :modal-append-to-body="false"
      :visible.sync="show"
      width="800px"
    >
      <div class="check-content">
        <div class="check-add">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <div class="row">
              <el-form-item>
                <p class="add-tip">Pmt #</p>
                <el-select v-model="formInline.periods" multiple placeholder="Select">
                  <el-option v-for="item in count" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <p class="add-tip">Date Received</p>
                <el-date-picker
                  :clearable="false"
                  v-model="formInline.date"
                  type="date"
                  format="MM/dd/yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <p class="add-tip">Payment Amount</p>
                <el-input v-model="formInline.amount" />
              </el-form-item>
              <el-form-item>
                <p class="add-tip">Check Number</p>
                <el-input v-model="formInline.check_number" />
              </el-form-item>
            </div>
          </el-form>
          <div style="text-align: right;">
            <el-button class="primary-button" type="primary" @click="addCheck">Add</el-button>
          </div>
        </div>
        <div class="lb_table table">
          <el-table :data="data.checks" :row-class-name="tableRowClassName">
            <el-table-column label="PAYMENT #" width="100">
              <template slot-scope="scope">
                <span v-for="(item,index) in scope.row.periods" :key="index" style="margin:0"> {{ index == scope.row.periods.length-1 && item || (item + ',') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="DATE RECEIVED" prop="date"></el-table-column>
            <el-table-column label="PAYMENT AMOUNT">
              <template slot-scope="scope">
                <span>{{scope.row.amount | currencyPrice}}</span>
              </template>
            </el-table-column>
            <el-table-column label="CHECK NUMBER" prop="check_number"></el-table-column>
            <el-table-column width="75">
              <template slot-scope="scope">
                <span class="btn" @click="cancelDel(scope.row.check_uuid)">Delete</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiCreateCheckPayment, apiDelCheckPayment } from "../../../API/api";
export default {
  props: ["data", "count"],
  data() {
    return {
      formInline: { periods: [], lease: "" },
      show: false
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 != 0) {
        return "become_angry";
      }
      return "";
    },
    addCheck(id) {
      this.formInline.lease=this.data.lease_id
      apiCreateCheckPayment(this.formInline)
        .then(res => {
          this.clear();
          this.$parent.getLease();
        })
        .catch(err => {
          this.$message.error(err.response.data.detail);
        });
    },
    cancelDel(id) {
      apiDelCheckPayment(id).then(res => {
        this.$parent.getLease();
      });
    },
    clear() {
      this.formInline = {
        periods: [],
        lease: this.data.lease_id,
        date: "",
        amount: "",
        check_number: ""
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/button.scss";
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.check {
  text-align: left;
  /deep/ .el-dialog__header {
    // background-color: #678993;
    position: relative;
    &::after{
      position:absolute;
      content: "";
      bottom: 0;
      left: 0;
      border: 1px solid #E1E9EF;
      width: calc(100% - 4px);
    }
  }
  /deep/ .el-dialog__title{
    font-family: "Roboto-Bold", sans-serif;
    font-size: 22px;
    color: #38425B;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #678993;
    font-weight: 600;
    font-size: 22px;
  }
  /deep/ .el-dialog__body {
    padding-top: 10px;
  }
  .primary-button{
    margin-bottom: 20px;
  }
  .check-add {
    .add-tip {
      margin: 0;
    }
    /deep/ .el-form-item {
      margin: 0 0 10px 0;
      width: 24%;
    }
    /deep/ .el-form--inline .el-form-item__content,
    /deep/ .el-input {
      width: 100%;
    }
  }
  .lb_table {
    padding: 0;
    font-size: 16px;
    .btn {
      color: #c66d65;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>