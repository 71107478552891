<template>
  <div class="disbursement-form">
    <el-dialog
      title="Disbursement Form"
      :visible.sync="show"
      width="800px"
      class="top"
    >
      <div class="disbursement-form" v-if="data.disbursement_form">
        <el-form :model="data.disbursement_form" label-position="left">
          <el-divider></el-divider>
          <table class="table-box" width="100%" cellspacing="0">
            <tr class="total-received">
              <td class="total-price">Total Received this Payment</td>
              <td colspan="3" class="total-price">
                ${{ data.received_amount }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>PAYMENT DATE</td>
              <td>REFERENCE</td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.processing_fee"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>Taxes</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.tax"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>Office Commission</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.office_commission"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>Agent Commission</td>
              <td>
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.agent_commission"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
              <td>
                <el-date-picker
                  v-if="$store.state.userInfo.is_admin === true"
                  v-model="qb_info.agent_bill_payment_date"
                  type="date"
                  placeholder=""
                  format="MM/dd/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 140px"
                  @change="changeQBAgentDate(data)"
                >
                </el-date-picker>
                <span v-else>{{qb_info.agent_bill_payment_date?qb_info.agent_bill_payment_date:null | dateFormat}}</span>
              </td>
              <td style="padding-left: 10px;width: 130px;">
                <el-input
                 v-if="$store.state.userInfo.is_admin === true"
                  @change="changeQBAgentRef(data)"
                  @keyup.enter.native="changeQBAgentRef(data)"
                  style="width: calc(100% - 10px);"
                  v-model="qb_info.agent_bill_payment_ref"
                />
                <span v-else>{{qb_info.agent_bill_payment_ref?qb_info.agent_bill_payment_ref:"" }}</span>
              </td>
            </tr>
            <tr>
              <td>Rent</td>
              <td>
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.rent_to_owner"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
              <td style="width: 140px">
                <el-date-picker
                  v-if="$store.state.userInfo.is_admin === true"
                  v-model="qb_info.homeowner_bill_payment_date"
                  type="date"
                  placeholder=""
                  format="MM/dd/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 140px"
                  @change="changeQBHomeownerDate(data)"
                >
                </el-date-picker>
                <span v-else >{{qb_info.homeowner_bill_payment_date?qb_info.homeowner_bill_payment_date:null | dateFormat
                  }}</span>
              </td>
              <td style="padding-left: 10px;width: 130px;">
                <el-input
                  v-if="$store.state.userInfo.is_admin === true"
                  @change="changeQBHomeownerRef(data)"
                  @keyup.enter.native="changeQBHomeownerRef(data)"
                  style="width: calc(100% - 10px);"
                  v-model="qb_info.homeowner_bill_payment_ref"
                />
                <span v-else>{{qb_info.homeowner_bill_payment_ref?qb_info.homeowner_bill_payment_ref:"" }}</span>
              </td>
            </tr>
            <tr>
              <td>Security Deposit</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    type="number"
                    :disabled="$store.state.userInfo.is_admin === false"
                    v-model="data.disbursement_form.security_deposit"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>Other Fees</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    :disabled="$store.state.userInfo.is_admin === false"
                    type="number"
                    v-model="data.disbursement_form.other_fee"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>Co-Broke Comisison</td>
              <td colspan="3">
                <el-form-item>
                  <el-input
                    :disabled="$store.state.userInfo.is_admin === false"
                    type="number"
                    v-model="data.disbursement_form.co_broke_commission"
                  >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <td>{{data.disbursement_form.co_broke_commission==='0.00'?"":co_broke_agency}}</td>
              <td colspan="3" class="total-price">
                ${{
                  (Number(data.disbursement_form.other_fee) +
                    Number(data.disbursement_form.security_deposit) +
                    Number(data.disbursement_form.rent_to_owner) +
                    Number(data.disbursement_form.processing_fee) +
                    Number(data.disbursement_form.office_commission) +
                    Number(data.disbursement_form.agent_commission) +
                    Number(data.disbursement_form.tax))+
                    Number(data.disbursement_form.co_broke_commission)
                    | currencyPrice
                }}
              </td>
            </tr>
          </table>
          <div class="btn">
            <el-button class="light-blue-button" @click="show = false"
              >Cancel</el-button
            >
            <el-button
              class="primary-button"
              @click="submitDisbursementForm"
              :loading="loading"
              :disabled="$store.state.userInfo.is_admin === false"
              >Submit Disbursement Form</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiUpdateDisbursement, apiUpdateQBInfor } from "../../../API/api";
function keepTwoDecimal(num) { // 采用四舍五入，保存两位小数
 
  return Math.round(parseFloat(num) * 100) / 100;
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
export default {
  props: ["data", "co_broke_agency", "qb_info"],
  data() {
    return {
      show: false,
      query: {},
      loading: false,
    };
  },
  filters:{
    agentNumber(data){
      if(data.indexOf(':')!=1){
        return data.split(":")[1]
      }else{
        return data
      }
    },
  },
  methods: {
    changeQBHomeownerRef(data) {
      let obj = {
        payment: data.payment_uuid,
        homeowner_bill_payment_ref: qb_info.homeowner_bill_payment_ref
          ? qb_info.homeowner_bill_payment_ref
          : null,
      };
      this.query=Object.assign({},obj)
      this.changeQB();
    },
    changeQBHomeownerDate(data){
      let obj = {
        payment: data.payment_uuid,
        homeowner_bill_payment_date: qb_info.homeowner_bill_payment_date
          ? qb_info.homeowner_bill_payment_date
          : null,
      };
      this.query=Object.assign({},obj)
      this.changeQB();
    },
    changeQBAgentRef(data){
      let obj = {
        payment: data.payment_uuid,
        agent_bill_payment_ref: qb_info.agent_bill_payment_ref
          ? qb_info.agent_bill_payment_ref
          : null
      };
      this.query=Object.assign({},obj)
      this.changeQB()
    },
    changeQBAgentDate(data){
      let obj = {
        payment: data.payment_uuid,
        agent_bill_payment_date: qb_info.agent_bill_payment_date
          ? qb_info.agent_bill_payment_date
          : null,
      };
      this.query=Object.assign({},obj)
      this.changeQB()
    },
    changeQB(data) {
      apiUpdateQBInfor(this.query)
        .then((res) => {
          this.$message.success("Success");
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    submitDisbursementForm() {
      let obj = {};
      obj = Object.assign({}, this.data.disbursement_form);
      delete obj.disbursement_form_uuid;
      delete obj.updated_at;
      delete obj.submit_at
      if (calcTotalPrice(obj) === Number(this.data.total_amount)) {
        if (!obj.agent_commission) {
          obj.agent_commission = 0.0;
        }
        if (!obj.office_commission) {
          obj.office_commission = 0.0;
        }
        if (!obj.other_fee) {
          obj.other_fee = 0.0;
        }
        if (!obj.processing_fee) {
          obj.processing_fee = 0.0;
        }
        if (!obj.rent_to_owner) {
          obj.rent_to_owner = 0.0;
        }
        if (!obj.security_deposit) {
          obj.security_deposit = 0.0;
        }
        if (!obj.co_broke_commission) {
          obj.co_broke_commission = 0.0;
        }
        if (!obj.tax) {
          obj.tax = 0.0;
        }
        this.loading = true;
        apiUpdateDisbursement(
          this.data.disbursement_form.disbursement_form_uuid,
          obj
        )
          .then((res) => {
            this.$message.success("Submit disbursement form successful");
          })
          .catch((err) => {
            this.$message.error("Submit disbursement form fail");
          })
          .finally(() => {
            this.loading = false;
            this.show = false;
          });
      } else {
        this.$message.warning("Amount mismatch");
      }
    },
    cancel() {
      this.data.disbursement_form.agent_commission = this.data.disbursement_form.office_commission = this.data.disbursement_form.security_deposit = this.data.disbursement_form.tax = this.data.disbursement_form.other_fee = this.data.disbursement_form.processing_fee = this.data.disbursement_form.rent_to_owner = this.data.disbursement_form.co_broke_commission=0.0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.disbursement-form {
  /deep/ .el-dialog {
    .el-dialog__header {
      text-align: left;
      .el-dialog__title {
        font-family: "Roboto-Bold", sans-serif;
        font-size: 22px;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-weight: 600;
      font-size: 22px;
      color: #678993;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-input {
      width: 200px;
    }
  }
  .table-box {
    //   padding: 0 20px;
    td {
      border-bottom: 1px solid #e1e9ef;
      text-align: left;
      height: 50px;
      &:first-of-type {
        padding: 0 0 0 20px;
      }
      &:last-of-type {
        padding: 0 5px 0 0;
      }
    }
    tr {
      color: #36425d;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      .total-price {
        font-family: "Roboto-Bold", sans-serif;
      }
      &:last-of-type {
        td {
          border: none;
        }
      }
    }
  }
  .btn {
    text-align: right;
    padding: 20px;
  }
}
</style>