<template>
    <div>
       <div class="payment-detail">
        <el-form :model="data" label-position="left">
          <div class="payment-period" v-for="(payment, index) in payments" :key="index" >
            <div class="payment-title">
              <span class="payment-item-title">Payment # {{ index + 1 }}</span>
              <div class="pay_due_date">
                <span style="width: 200px">Payment due on</span>
                <el-date-picker v-model="payment.due_date" :disabled="data.status && data.status != 'Draft'" type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="expireTimeOption" ></el-date-picker>
              </div>
            </div>
            <div class="payment-content">
              <div v-if="data.is_co_broke_lease&&data.represent==='tenant'">
                <el-form-item label="Co-broke Commission">
                <label >{{ Number(data.agent_commission)+Number(data.office_commission) | currencyPrice("$ ") }}</label >
              </el-form-item>
              <el-form-item class="total-due" label="Total Due This Payment">
                <label>{{ Number(data.agent_commission)+Number(data.office_commission) | currencyPrice("$ ") }}</label>
              </el-form-item>
              </div>
              <div v-else>
              <el-form-item label="Processing Fee" v-if="index + 1 === 1&&payment.bill&&payment.bill.length!=0&&payment.bill['processing_fee']">
                <label >{{ payment.bill["processing_fee"] | currencyPrice("$ ") }}</label >
              </el-form-item>
              <el-form-item label="Rent" v-if="payment.bill&&payment.bill.length!=0&&payment.bill.rent">
                <el-input type="number" v-model="payment.bill.rent" :disabled="selectPeriod === 1 || data.status && data.status != 'Draft'" @change="rentChange(payment, index)" >
                  <strong slot="prefix">$</strong>
                </el-input>
              </el-form-item>
              <el-form-item label="Occupancy Taxes" v-if="index + 1 === 1&&payment.bill&&payment.bill.length!=0&&payment.bill['tax']">
                <label>{{ payment.bill["tax"] | currencyPrice("$ ") }}</label>
              </el-form-item>
              <el-form-item label="Security Deposit" v-if="index + 1 === selectPeriod&&payment.bill&&payment.bill.length!=0&&payment.bill['security_deposit']" >
                <label >{{ payment.bill["security_deposit"] | currencyPrice("$ ") }}</label >
              </el-form-item>
              <el-form-item label="Other Fees" v-if="index + 1 === selectPeriod&&payment.bill&&payment.bill.length!=0&&payment.bill['other_fee']" >
                <label>{{ payment.bill["other_fee"] | currencyPrice("$ ") }}</label>
              </el-form-item>
              <el-form-item class="total-due" label="Total Due This Payment">
                <label>{{ payment.total_amount | currencyPrice("$ ") }}</label>
              </el-form-item>
              </div>
              <el-form-item label="Total Un-scheduled Payments" class="total-unscheduled" v-if="index + 1 === selectPeriod && showExcess" >
                <label>$ {{ excess }}</label>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
</template>
<script>
export default {
    props:["data", "selectPeriod", "payments", "excess"],
    data(){
        return { 
          showExcess: false,
          expireTimeOption: {
          disabledDate(date) {
          var times = Date.now() - 24 * 60 * 60 * 1000;
          return date.getTime() < times;
        },
       },
        }
    },
    methods:{
        rentChange(payment, index){
          this.$emit("rentChange",payment, index)
        }
    }
}
</script>
<style lang="scss" scoped>
  .payment-detail {
    padding-bottom: 40px;
    .payment-period {
      padding-bottom: 20px;
      position: relative;
      .payment-content {
        /deep/ .el-form-item {
          display: flex;
          margin-bottom: 5px;
          .el-form-item__label {
            width: 300px;
          }
          .el-input__prefix {
            left: 1px;
            width: 35px;
            top: 1.5px;
            height: calc(100% - 3px);
            background-color: #f4f9fc;
          }
          .el-form-item__content {
            width: calc(100% - 300px);
            text-align: left;
            .el-input__inner {
              width: 200px;
              padding-left: 40px;
            }
            .other-fee {
              display: flex;
              justify-content: space-between;
              label {
                width: 300px;
              }
              .el-input__inner {
                width: 100%;
              }
              @media (max-width: 772px) {
                display: block;
              }
            }
          }
          @media (max-width:552px) {
              .el-form-item__label {
               width: 165px;
             }
             .el-form-item__content{ width: calc(100% - 165px); }
          }
        }
        /deep/ .el-form-item.total-due {
          font-family: "Roboto-Bold", sans-serif;
        }
        /deep/ .el-form-item.total-unscheduled {
          font-family: "Roboto-Bold", sans-serif;
          color: red;
          .el-form-item__label {
            color: red;
          }
        }
      }
      &::after {
        content: "";
        border: 1px Dashed #e1e9ef;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    .payment-title {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .payment-item-title {
        font-family: "Roboto-Bold", sans-serif;
        font-size: 16px;
      }
      .pay_due_date {
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 252px;
        }
      }
    }
    @media (max-width:992px) {
      .payment-period .payment-content{
        /deep/ .el-form-item {
          display: flex;
          margin-bottom: 5px;
          .el-form-item__content {
            .el-input__inner {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width:772px) {
      .payment-title {display: block;text-align: left;}
    }
  }
</style>